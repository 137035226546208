import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service.js";
import i18n from "@/core/plugins/vue-i18n.js";

const state = {
  errors: null,
  collection: [],
};

const getters = {
  transportOrderCollection(state) {
    return state.collection;
  },

  getTransportOrderByID: (state) => (id) => {
    let item = state.collection.find((item) => item.id == id);
    if (!item) {
      item = { name: "" };
    }
    return item;
  },
};

const actions = {
  fetchTransportOrder(context) {
    return new Promise((resolve) => {
      let appLocale = i18nService.getActiveLanguage();
      ApiService.get("transport/transportOrder")
        .then(({ data }) => {
          // create localized fields
          let dataLocalized = data.map((item) => {
            item.state = i18n.t("TRANSPORT.status_" + item.status);
            if (item.translations) {
              let translations = item.translations[appLocale];
              if (translations) {
                Object.keys(translations).forEach((property) => {
                  item[property] = item.translations[appLocale][property];
                });
              } else {
                for (const [key, value] of Object.entries(
                  item.translations[Object.keys(item.translations)[0]]
                )) {
                  if ("attachments" != key) {
                    item[key] = value;
                  }
                }
              }
            }

            return item;
          });

          context.commit("fetchTransportOrder", dataLocalized);
          resolve();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    });
  },
};

const mutations = {
  fetchTransportOrder(state, data) {
    state.collection = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
