// USA
export const locale = {
  TRANSLATOR: {
    SELECT: "Select your language",
  },
  MENU: {
    NEW: "new",
    ACTIONS: "Actions",
    CREATE_POST: "Create New Post",
    PAGES: "Pages",
    FEATURES: "Features",
    APPS: "Apps",
    DASHBOARD: "Dashboard",

    systemSettings: "System Settings",
    users: "Users",
    userGroups: "User Groups",
    roles: "Roles",
    currencies: "Currencies",
    countries: "Countries",
    companySettings: "Company Settings",
    departments: "Departments",
    sites: "Sites",
  },
  AUTH: {
    SIGN_OUT_BUTTON: "Sign out",
    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Submit",
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      FORGOT_BUTTON: "Forgot Password",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact",
    },
    LOGIN: {
      TITLE: "Login Account",
      BUTTON: "Sign In",
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset.",
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfuly registered.",
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "Username",
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid",
    },
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields",
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Customers",
      CUSTOMERS_LIST: "Customers list",
      NEW_CUSTOMER: "New Customer",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Customer Delete",
        DESCRIPTION: "Are you sure to permanently delete this customer?",
        WAIT_DESCRIPTION: "Customer is deleting...",
        MESSAGE: "Customer has been deleted",
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Customers Delete",
        DESCRIPTION: "Are you sure to permanently delete selected customers?",
        WAIT_DESCRIPTION: "Customers are deleting...",
        MESSAGE: "Selected customers have been deleted",
      },
      UPDATE_STATUS: {
        TITLE: "Status has been updated for selected customers",
        MESSAGE: "Selected customers status have successfully been updated",
      },
      EDIT: {
        UPDATE_MESSAGE: "Customer has been updated",
        ADD_MESSAGE: "Customer has been created",
      },
    },
  },

  FORMINPUTNAMES: {
    total_net: "Total net",
    total_gross: "Total gross",
    sales_point_id: "Sales point",
    owner_id: "Owner",
    paid: "Paid",
    lost: "Lost",
    source_type_id: "Source type",
    locale: "Locale",
    customer: "Customer",
    billingAddress: "Billing address",
    shippingAddress: "Shipping address",
    expiration_date: "Expiration date",
    delivery_date: "Delivery date",
    delivery_days: "Delivery days",
    price_list_id: "Priece list",
    discount_value: "Discount value",
    discountNetValue: "Discount net value",
    notes: "Comment",
    status: "Státusz",
  },
  EMAIL: {
    offer: "Quotation",
    subject: "Your quote is ready",
    invocation: "Dear ",
    message1: "Thank you for your patience, the quote is ready!",
    message2: "Identifier: ",
    message3:
      'The quote will appear on a new browser tab by clicking the "View" button. You can order the products immediately if you click on the "Order" button at the bottom of the offer!',
    subject_ordered: "Your order has been recorded",
    message1_ordered: "Thank you for your confirmation, your order has been recorded!",
    message3_ordered:
      'We will send you the proforma invoice soon, and we will contact you before the delivery! If you click on the "View" button, your order will be opened in a new browser tab.',
  },

  MEDIA_LIBRARY: {
    create_new_directory: "New directory",
    rename_directory: "Rename directory",
    rename_file: "Rename file",
    file_upload: "Upload file",
    please_select: "Please select",
    uploading: "Uploading",
    edit_image: "Edit image",
    crop_and_save: "Crop and save",
    file_upload_max_size: "Max upload file size",
    file_upload_max_count: "Upload file max count",
    select_media: "Select media",
  },

  SALESORDERSSTATUSES: {
    new: "New",
    waiting_for_offer: "Waiting for offer",
    offer_approval: "Offer approval",
    offer_done: "Offer done",
    order_approval: "Order approval",
    ordered: "Ordered",
    contracted: "Contracted",
    to_be_produced: "To be produced",
    in_production: "In production",
    to_be_delivered: "To be delivered",
    preparation: "Preparation",
    delivered: "Delivered",
    closed: "Closed",
    revoked: "Revoked",
  },

  FORMS: {
    new: "Új",
    menu: "Menü",
    edit_menu: "Menü szerkesztése",
    menus: "Menü szerkesztő",
    cancel: "Cancel",
    save: "Save",
    status: "Státusz",
    name: "Név",
    label: "Cím",
    parent: "Szülő",
    icon: "Ikon",
    target: "Cél",
    slug: "Slug",
    destinations: "Desztinációk",
    active: "Aktív",
    inactive: "Inaktív",
    are_you: "Valóban törölni szeretnéd a kiválasztott elemet",
    password: "password",
  },

  ACTIVITY: {
    not_logged_id: "Customer",
  },

  EQUIPMENT: {
    status_null: "",
    status_undefined: "",
    status_0: "New",
    status_5: "In progress",
    status_10: "Completed",
  },

  ADJUSTMENT: {
    status_null: "",
    status_undefined: "",
    status_0: "New",
    status_1: "Under production",
    status_2: "Under procurement",
    status_3: "Remainder",
    status_4: "On hand",
    status_5: "Built in",
    status_6: "Released",
    status_7: "Shipping",
    status_8: "Delivered",
    status_9: "Damaged",
    status_10: "Lost",

    typeOf_1: "Add",
    typeOf_2: "Remove",
    typeOf_3: "Edit",
  },

  TRANSPORT: {
    status_0: "New",
    status_5: "In progress",
    status_10: "Closed",
  },

  ITINER: {
    status_0: "New",
    status_5: "In progress",
    status_10: "Completed",
    status_15: "Closed",
  },

  PAYMENT: {
    cash: "Cash",
    bank_transfer: "Bank transfer",
    credit_card: "Credit card",
  },

  TRANSACTION_TYPE: {
    ModulesStockEntitiesTransfer: "Transfer",
    ModulesSalesEntitiesSalesOrder: "Sales order",
    ModulesManufactureEntitiesProject: "Project",
    ModulesProcurementEntitiesPurchaseOrder: "Purchase order",
    // ModulesTransportEntitiesTransportOrder: "Transport entities order",
  },
  ALERT: {
    choose_only_maxFiles: "You can choose only {maxFiles} files!",
    file_info_title: "File information",
    created_at: "Created at",
    name: "Name",
    mime_type: "Mime type",
    path: "Path",
    size: "Size",
    width: "Width",
    height: "Height",
    pixel: "Pixel",
  },
};
