import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import userGroups from "./userGroup.module";
import user from "./user.module";
import role from "./role.module";
import currency from "./currency.module";
import country from "./country.module";
import department from "./department.module";
import site from "./site.module";
import webSite from "./website.module";
import companyType from "./companyType.module";
import companyCategory from "./companyCategory.module";
import industry from "./industry.module";
import qualification from "./qualification.module";
import supplierType from "./supplierType.module";
import contactList from "./contactList.module";
import company from "./company.module";
import priceList from "./priceList.module";
import quantityUnit from "./quantityUnit.module";
import category from "./category.module";
import item from "./item.module";
import salesPoint from "./salesPoint.module";
import sourceType from "./sourceType.module";
import salesOrder from "./salesOrder.module";
import storage from "./storage.module";
import adjustment from "./adjustment.module";
import receive from "./receive.module";
import release from "./release.module";
import transfer from "./transfer.module";
import stockItem from "./stockItem.module";
import transportOrder from "./transportOrder.module";
import itiner from "./itiner.module";
import vehicle from "./vehicle.module";
import project from "./project.module";
import purchaseOrder from "./purchaseOrder.module";
import position from "./position.module";
import education from "./education.module";
import employee from "./employee.module";
import equipment from "./equipment.module";
import advance from "./advance.module";
import webCategory from "./webCategory.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    userGroups,
    user,
    role,
    currency,
    country,
    department,
    site,
    companyType,
    companyCategory,
    industry,
    qualification,
    supplierType,
    contactList,
    company,
    priceList,
    quantityUnit,
    category,
    webCategory,
    item,
    webSite,
    salesPoint,
    sourceType,
    salesOrder,
    storage,
    adjustment,
    receive,
    release,
    transfer,
    transportOrder,
    stockItem,
    vehicle,
    itiner,
    project,
    purchaseOrder,
    education,
    position,
    employee,
    equipment,
    advance,
  },
});
