import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service.js";

export const FETCH_COUNTRY = "fetchCountry";
export const FETCH_ALL_COUNTRY = "fetchAllCountry";
export const COUNTRY_COLLECTION = "countryCollection";
export const COUNTRY_ALL_COLLECTION = "countryAllCollection";

const state = {
  errors: null,
  collection: [],
  collectionAll: [],
};

const getters = {
  [COUNTRY_COLLECTION](state) {
    return state.collection;
  },
  [COUNTRY_ALL_COLLECTION](state) {
    return state.collectionAll;
  },
};

const actions = {
  [FETCH_COUNTRY](context) {
    let appLocale = i18nService.getActiveLanguage();
    ApiService.get("country")
      .then(({ data }) => {
        // create localized fields
        let dataLocalized = data.map((item) => {
          if (item.translations.length == 0) {
            item.name = "Name not defined";
            return item;
          }

          if (item.translations[appLocale]) {
            item.name = item.translations[appLocale].name;
          } else {
            item.name =
              item.translations[Object.keys(item.translations)[0]].name;
          }

          return item;
        });

        context.commit(FETCH_COUNTRY, dataLocalized);
      })
      .catch((error) => {
        console.log("Error!: ", error);
      });
  },

  [FETCH_ALL_COUNTRY](context) {
    let appLocale = i18nService.getActiveLanguage();
    // ApiService.get("country")
    ApiService.get("country/all")
      .then(({ data }) => {
        // create localized fields
        let dataLocalized = data.map((item) => {
          if (item.translations.length == 0) {
            item.name = "Name not defined";
            return item;
          }

          if (item.translations[appLocale]) {
            item.name = item.translations[appLocale].name;
          } else {
            item.name =
              item.translations[Object.keys(item.translations)[0]].name;
          }

          return item;
        });

        context.commit(FETCH_ALL_COUNTRY, dataLocalized);
      })
      .catch((error) => {
        console.log("Error!: ", error);
      });
  },
};

const mutations = {
  [FETCH_COUNTRY](state, data) {
    state.collection = data;
  },

  [FETCH_ALL_COUNTRY](state, data) {
    state.collectionAll = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
