import ApiService from "@/core/services/api.service";

const state = {
  errors: null,
  collection: []
};

const getters = {
  salesPointCollection(state) {
    return state.collection;
  }
};

const actions = {
  fetchSalesPoint(context) {
    ApiService.get("sales/salesPoint")
      .then(({ data }) => {
        context.commit("fetchSalesPoint", data);
      })
      .catch(error => {
        console.log("Error!: ", error);
      });
  }
};

const mutations = {
  fetchSalesPoint(state, data) {
    state.collection = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
