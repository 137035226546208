import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service.js";

const state = {
  errors: null,
  collection: [],
};

const getters = {
  purchaseOrderCollection(state) {
    return state.collection;
  },

  getPurchaseOrderByID: (state) => (id) => {
    let item = state.collection.find((item) => item.id == id);
    if (!item) {
      item = { name: "" };
    }
    return item;
  },
};

const actions = {
  fetchPurchaseOrder(context) {
    return new Promise((resolve) => {
      let appLocale = i18nService.getActiveLanguage();
      ApiService.get("procurement/purchaseOrder")
        .then(({ data }) => {
          // create localized fields
          let dataLocalized = data.map((item) => {
            if (item.translations) {
              let translations = item.translations[appLocale];
              if (translations) {
                Object.keys(translations).forEach((property) => {
                  item[property] = item.translations[appLocale][property];
                });
              } else {
                for (const [key, value] of Object.entries(
                  item.translations[Object.keys(item.translations)[0]]
                )) {
                  if ("attachments" != key) {
                    item[key] = value;
                  }
                }
              }
            }

            item.itemsAllNames = "";
            item.itemsShortNames = "";
            item.items = item.items.map((i, index) => {
              if (i.translations.length == 0) {
                i.name = "Name not defined";
              }
              if (i.translations[appLocale]) {
                i.name = i.translations[appLocale].name;
              } else {
                i.name = i.translations[Object.keys(i.translations)[0]].name;
              }

              item.itemsAllNames += i.reference + " | " + i.name + "; <br>";
              if (index < 5) {
                item.itemsShortNames += i.reference + " | " + i.name + "; ";
              }
              return i;
            });

            return item;
          });

          context.commit("fetchPurchaseOrder", dataLocalized);
          resolve();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    });
  },
};

const mutations = {
  fetchPurchaseOrder(state, data) {
    state.collection = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
