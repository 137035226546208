import ApiService from "@/core/services/api.service";
const state = {
  errors: null,
  collection: []
};

const getters = {
  companyCollection(state) {
    return state.collection;
  }
};

const actions = {
  fetchCompany(context) {
    return new Promise(resolve => {
      ApiService.get("contact/company")
        .then(({ data }) => {
          context.commit("fetchCompany", data);
        })
        .catch(error => {
          console.log("Error!: ", error);
        });
      resolve();
    });
  }
};

const mutations = {
  fetchCompany(state, data) {
    state.collection = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
