import ApiService from "@/core/services/api.service";
import { locale } from "@/core/config/i18n/en.js";
import i18nService from "@/core/services/i18n.service.js";

const state = {
  errors: null,
  collection: [],
};

const getters = {
  salesOrderCollection(state) {
    return state.collection;
  },
};

const actions = {
  fetchSalesOrder(context, url) {
    return new Promise((resolve) => {
      // let statuses = [];
      // if (context.getters.statuses.sales) {
      //   statuses = context.getters.statuses.sales.salesOrder;
      // }
      let salesOrderStatuses = [];
      let statuses = JSON.parse(window.localStorage.getItem("SOStatuses"));
      statuses.forEach((item) => {
        // console.log("locale", locale, item.key);
        salesOrderStatuses[item.key] = locale.SALESORDERSSTATUSES[item.value];
      });

      window.localStorage.setItem(
        "salesOrderStatuses",
        JSON.stringify(salesOrderStatuses)
      );

      ApiService.get(url)
        .then(({ data }) => {
          // let salesOrderStatuses = statuses.map((item) => {
          //     return locale.SALESORDERSSTATUSES[item.value]
          // })

          let appLocale = i18nService.getActiveLanguage();

          data = data.map((item) => {
            item.customerName = item.company_name ?? item.contact_name;

            item.salesPersonName = item.owner_name ?? "";

            item.status = salesOrderStatuses[item.status];

            // item.currency = "HUF"
            item.currency = item.currency.id;
            // item.itemsAllNames = "";
            item.itemsShortNames = "";
            item.items = item.items.map((i, index) => {
              if (i.translations.length == 0) {
                i.name = "Name not defined";
              }
              if (i.translations[appLocale]) {
                i.name = i.translations[appLocale].name;
              } else {
                i.name = i.translations[Object.keys(i.translations)[0]].name;
              }

              // item.itemsAllNames += i.reference + " | " + i.name + "; <br>";
              if (index < 2) {
                item.itemsShortNames += i.reference + " | " + i.name + "; ";
              }
              return i;
            });
            return item;
          });

          // if (!window.localStorage.getItem("salesOrderStatuses")) {
          // }

          context.commit("fetchSalesOrder", data);
          resolve();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    });
  },
};

const mutations = {
  fetchSalesOrder(state, data) {
    state.collection = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
