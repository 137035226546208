import ApiService from "@/core/services/api.service";

const state = {
  errors: null,
  collection: []
};

const getters = {
  releaseCollection(state) {
    return state.collection;
  },

  getReleaseByID: state => id => {
    return state.collection.find(item => item.id == id);
  }
};

const actions = {
  fetchRelease(context) {
    ApiService.get("stock/release")
      .then(({ data }) => {
        context.commit("fetchRelease", data);
      })
      .catch(error => {
        console.log("Error!: ", error);
      });
  }
};

const mutations = {
  fetchRelease(state, data) {
    state.collection = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
