// USA
export const locale = {
  TRANSLATOR: {
    SELECT: "Select your language",
  },
  MENU: {
    NEW: "new",
    ACTIONS: "Actions",
    CREATE_POST: "Create New Post",
    PAGES: "Pages",
    FEATURES: "Features",
    APPS: "Apps",
    DASHBOARD: "Dashboard",
  },
  AUTH: {
    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Submit",
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      FORGOT_BUTTON: "Forgot Password",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact",
    },
    LOGIN: {
      TITLE: "Login Account",
      BUTTON: "Sign In",
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset.",
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfuly registered.",
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "Username",
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid",
      // "Invalid Email or Password": "Invalid Email or Password",
      token_expired: "Token expired, You are logged out",
    },
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields",
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Customers",
      CUSTOMERS_LIST: "Customers list",
      NEW_CUSTOMER: "New Customer",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Customer Delete",
        DESCRIPTION: "Are you sure to permanently delete this customer?",
        WAIT_DESCRIPTION: "Customer is deleting...",
        MESSAGE: "Customer has been deleted",
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Customers Delete",
        DESCRIPTION: "Are you sure to permanently delete selected customers?",
        WAIT_DESCRIPTION: "Customers are deleting...",
        MESSAGE: "Selected customers have been deleted",
      },
      UPDATE_STATUS: {
        TITLE: "Status has been updated for selected customers",
        MESSAGE: "Selected customers status have successfully been updated",
      },
      EDIT: {
        UPDATE_MESSAGE: "Customer has been updated",
        ADD_MESSAGE: "Customer has been created",
      },
    },
  },
  PERMISSIONS: {
    module_contacts_menu: "Contact modul",
    module_inventory_menu: "Inventory modul",
    module_sales_menu: "Sales modul",
    module_humanResources_menu: "HR modul",
    module_system_menu: "System settings modul",
    module_companySettings_menu: "Company setting modul",
    contacts_contact_menu: "Contacts - Contact list menu",
    contacts_contact_create: "Contacts - Create contact person",
    contacts_contact_delete: "Contacts - Delete contact person",
    contacts_contact_update: "Contacts - Update contact person",
    contacts_contact_view: "Contacts - View contact person",
    contacts_company_menu: "Contacts - Companies menu",
    contacts_company_create: "Contacts - Create company",
    contacts_company_delete: "Contacts - Delete company",
    contacts_company_update: "Contacts - Update company",
    contacts_company_view: "Contacts - View company",
    contacts_companyCategory_menu: "Contacts - Company categories menu",
    contacts_companyCategory_create: "Contacts - Create company category",
    contacts_companyCategory_delete: "Contacts - Delete company category",
    contacts_companyCategory_update: "Contacts - Update company category",
    contacts_companyCategory_view: "Contacts - View company category",
    contacts_companyType_menu: "Contacts - Company types menu",
    contacts_companyType_create: "Contacts - Create company type",
    contacts_companyType_delete: "Contacts - Delete company type",
    contacts_companyType_update: "Contacts - Update company type",
    contacts_companyType_view: "Contacts - View company type",
    contacts_supplierType_menu: "Contacts - Supplier types menu",
    contacts_supplierType_create: "Contacts - Create supplier type",
    contacts_supplierType_delete: "Contacts - Delete supplier type",
    contacts_supplierType_update: "Contacts - Update supplier type",
    contacts_supplierType_view: "Contacts - View supplier type",
    contacts_industry_menu: "Contacts - Industries menu",
    contacts_industry_create: "Contacts - Create industry",
    contacts_industry_delete: "Contacts - Delete industry",
    contacts_industry_update: "Contacts - Update industry",
    contacts_industry_view: "Contacts - View industry",
    contacts_qualification_menu: "Contacts - Qualifications menu",
    contacts_qualification_create: "Contacts - Create qualification",
    contacts_qualification_delete: "Contacts - Delete qualification",
    contacts_qualification_update: "Contacts - Update qualification",
    contacts_qualification_view: "Contacts - View qualification",
    inventory_item_menu: "Inventory - Items menu",
    inventory_item_create: "Inventory - Create item",
    inventory_item_delete: "Inventory - Delete item",
    inventory_item_update: "Inventory - Update item",
    inventory_item_view: "Inventory - View item",
    inventory_itemCategory_menu: "Inventory - Item categories menu",
    inventory_itemCategory_create: "Inventory - Create item category",
    inventory_itemCategory_delete: "Inventory - Delete item category",
    inventory_itemCategory_update: "Inventory - Update item category",
    inventory_itemCategory_view: "Inventory - View item category",
    inventory_quantityUnit_menu: "Inventory - Quantity units menu",
    inventory_quantityUnit_create: "Inventory - Create quantity unit",
    inventory_quantityUnit_delete: "Inventory - Delete quantity unit",
    inventory_quantityUnit_update: "Inventory - Update quantity unit",
    inventory_quantityUnit_view: "Inventory - View quantity unit",
    inventory_priceList_menu: "Inventory - Pricelists menu",
    inventory_priceList_create: "Inventory - Create pricelist",
    inventory_priceList_delete: "Inventory - Delete pricelist",
    inventory_priceList_update: "Inventory - Update pricelist",
    inventory_priceList_view: "Inventory - View pricelist",
    inventory_priceListItem_menu: "Inventory - Item pricelists menu",
    inventory_priceListItem_create: "Inventory - Create item pricelist",
    inventory_priceListItem_delete: "Inventory - Delete item pricelist",
    inventory_priceListItem_update: "Inventory - Update item pricelist",
    inventory_priceListItem_view: "Inventory - View item pricelist",

    sales_salesOrder_menu: "Sales - Sales orders menu",
    sales_salesOrder_create: "Sales - Create Sales order",
    sales_salesOrder_delete: "Sales - Delete Sales order",
    sales_salesOrder_update: "Sales - Update Sales order",
    sales_salesOrder_view: "Sales - View Sales order",
    sales_salesOrder_assign_group: "Sales - Assign Sales order to group",
    sales_salesOrder_assign_person: "Sales - Assign Sales order to person",
    sales_salesOrder_assign_self: "Sales - Assign Sales order to self",
    sales_salesOrder_approve: "Sales - Approve Sales order",
    sales_salesOrder_contract: "Sales - Contract Sales order",
    sales_salesOrder_lost: "Sales - Lost Sales order",
    sales_salesOrder_revoke: "Sales - Revoke Sales order",
    sales_salesOrder_close: "Sales - Close Sales order",
    sales_salesPoint_menu: "Sales - Sales points menu",
    sales_salesPoint_create: "Sales - Create Sales point",
    sales_salesPoint_delete: "Sales - Delete Sales point",
    sales_salesPoint_update: "Sales - Update Sales point",
    sales_salesPoint_view: "Sales - View Sales point",
    sales_sourceType_menu: "Sales - Source types menu",
    sales_sourceType_create: "Sales - Create source type",
    sales_sourceType_delete: "Sales - Delete source type",
    sales_sourceType_update: "Sales - Update source type",
    sales_sourceType_view: "Sales - View source type",

    humanResources_employee_menu: "HR - Employees menu",
    humanResources_employee_create: "HR - Create employee",
    humanResources_employee_delete: "HR - Delete employee",
    humanResources_employee_update: "HR - Update employee",
    humanResources_employee_update_self: "HR - Update employee self",
    humanResources_employee_view: "HR - View employee",
    humanResources_employee_view_self: "HR - View employee self",
    humanResources_position_menu: "HR - Positions menu",
    humanResources_position_create: "HR - Create position",
    humanResources_position_delete: "HR - Delete position",
    humanResources_position_update: "HR - Update position",
    humanResources_position_update_self: "HR - Update position self",
    humanResources_position_view: "HR - View position",
    humanResources_position_view_self: "HR - View position self",
    humanResources_education_menu: "HR - Education menu",
    humanResources_education_create: "HR - Create education",
    humanResources_education_delete: "HR - Delete education",
    humanResources_education_update: "HR - Update education",
    humanResources_education_view: "HR - View education",

    system_setting_menu: "System settings - System settings menu",
    system_setting_update: "System settings - Update system settings",
    system_setting_view: "System settings - View system settings",
    system_user_menu: "System settings - Users menu",
    system_user_create: "System settings - Create user",
    system_user_delete: "System settings - Delete user",
    system_user_update: "System settings - Update user",
    system_user_update_self: "System settings - Update user self",
    system_user_view: "System settings - View user",
    system_user_view_self: "System settings - View user self",
    system_userGroup_menu: "System settings - User groups menu",
    system_userGroup_create: "System settings - Create user group",
    system_userGroup_delete: "System settings - Delete user group",
    system_userGroup_update: "System settings - Update user group",
    system_userGroup_update_self: "System settings - Update user group self",
    system_userGroup_view: "System settings - View user group",
    system_userGroup_view_self: "System settings - View user group self",
    system_role_menu: "System settings - Roles menu",
    system_role_create: "System settings - Create role",
    system_role_delete: "System settings - Delete role",
    system_role_update: "System settings - Update role",
    system_role_view: "System settings - View role",
    system_country_menu: "System settings - Countries menu",
    system_country_update: "System settings - Update country",
    system_country_view: "System settings - View country",
    system_currency_menu: "System settings - Currencies menu",
    system_currency_create: "System settings - Create currency ",
    system_currency_delete: "System settings - Delete currency",
    system_currency_update: "System settings - Update currency",
    system_currency_view: "System settings - View currency",

    companySettings_department_menu: "Company settings - Departments menu",
    companySettings_department_create: "Company settings - Create department",
    companySettings_department_delete: "Company settings - Delete department",
    companySettings_department_update: "Company settings - Update department",
    companySettings_department_view: "Company settings - View department",
    companySettings_site_menu: "Company settings - Sites menu",
    companySettings_site_create: "Company settings - Create site",
    companySettings_site_delete: "Company settings - Delete site",
    companySettings_site_update: "Company settings - Update site",
    companySettings_site_view: "Company settings - View site",

    system: "System settings",
    companySettings: "Company settings",
    contacts: "Contacts",
    inventory: "Inventory",
    sales: "Sales",
    humanResources: "HR",
    module: "Modules",
  },

  SALESORDERSSTATUSES: {
    new: "New",
    waiting_for_offer: "Waiting for offer",
    offer_approval: "Offer approval",
    offer_done: "Offer done",
    order_approval: "Order approval",
    ordered: "Ordered",
    contracted: "Contracted",
    to_be_produced: "To be produced",
    in_production: "In production",
    to_be_delivered: "To be delivered",
    delivered: "Delivered",
    closed: "Closed",
    revoked: "Revoked",
  },

  FORMINPUTNAMES: {
    total_net: "Total net",
    total_gross: "Total gross",
    sales_point_id: "Sales point",
    owner_id: "Owner",
    paid: "Paid",
    lost: "Lost",
    source_type_id: "Source type",
    locale: "Locale",
    customer: "Customer",
    billingAddress: "Billing address",
    shippingAddress: "Shipping address",
    expiration_date: "Expiration date",
    delivery_date: "Delivery date",
    delivery_days: "Delivery days",
    price_list_id: "Priece list",
    discount_value: "Discount value",
    discountNetValue: "Discount net value",
    notes: "Comment",
    status: "Status",
  },
  EMAIL: {
    offer: "Árajánlat",
    subject: "Oferta dumneavoastra este gata",
    invocation: "Stimate ",
    message1:
      "Va multumim pentru rabdare, oferta dumneavaostra este finalizata!",
    message2: "Inregistrare: ",
    message3:
      'Apasand butonul "Vizualizare", oferta va aparea intr-un nou tab al browser-ului dumneavaostra. Daca oferta este pe placul dumneavaostra si ati dori sa depuneti o comanda ferma va rugam sa apasati butonul "Comanda", situat la sfarsitul ofertei!',
    subject_ordered: "Comanda dumneavoastră a fost înregistrată",
    message1_ordered:
      "Vă mulțumim pentru confirmare, comanda dvs. a fost înregistrată!",
    message3_ordered:
      'O să vă trimitem factura proformă și vă vom contacta înainte de livrare! Butonul "Vizualizare" va afisa intr-un nou tab al browser-ului,  comanda dumneavaostra .',
  },
};
