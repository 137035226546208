import ApiService from "@/core/services/api.service";
// import i18nService from "@/core/services/i18n.service.js";

const state = {
  errors: null,
  collection: [],
};

const getters = {
  storageCollection(state) {
    return state.collection.filter((item) => item.type == 1);
  },

  storageCollectionAll(state) {
    return state.collection.filter((item) => item.num_of_items > 0);
  },

  getStorageByID: (state) => (id) => {
    return state.collection.find((item) => item.id == id);
  },

  getStoragesByIds: (state) => (ids) => {
    return state.collection.filter((item) => ids.includes(item.id));
  },
};

const actions = {
  fetchStorage(context) {
    ApiService.get("stock/storage")
      .then(({ data }) => {
        context.commit("fetchStorage", data);
      })
      .catch((error) => {
        console.log("Error!: ", error);
      });
  },
};

const mutations = {
  fetchStorage(state, data) {
    state.collection = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
