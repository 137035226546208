import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service.js";

const state = {
  errors: null,
  collection: [],
};

const getters = {
  equipmentCollection(state) {
    return state.collection;
  },

  getEquipmentByID: (state) => (id) => {
    let item = state.collection.find((item) => item.id == id);
    if (!item) {
      item = { name: "" };
    }
    return item;
  },
};

const actions = {
  fetchEquipment(context) {
    return new Promise((resolve) => {
      let appLocale = i18nService.getActiveLanguage();
      ApiService.get("maintenance/equipment")
        .then(({ data }) => {
          let dataLocalized = data.map((item) => {
            if (!item.equipment_name) {
              if (item.equipment_translations) {
                item.equipment_name = item.equipment_translations[appLocale]
                  ? item.equipment_translations[appLocale].name
                  : item.equipment_translations[0].name;
              }
            }

            return item;
          });

          context.commit("fetchEquipment", dataLocalized);
          resolve();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    });
  },
};

const mutations = {
  fetchEquipment(state, data) {
    state.collection = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
