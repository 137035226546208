import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service.js";

export const FETCH_DEPARTMENT = "fetchDepartment";
export const DEPARTMENT_COLLECTION = "departmentCollection";

const state = {
  errors: null,
  collection: []
};

const getters = {
  [DEPARTMENT_COLLECTION](state) {
    return state.collection;
  }
};

const actions = {
  [FETCH_DEPARTMENT](context) {
    let appLocale = i18nService.getActiveLanguage();
    ApiService.get("companySetting/department")
      .then(({ data }) => {
        // create localized fields
        let dataLocalized = data.map(item => {
          if (item.translations.length == 0) {
            item.name = "Name not defined";
            return item;
          }

          if (item.translations[appLocale]) {
            item.name = item.translations[appLocale].name;
          } else {
            item.name =
              item.translations[Object.keys(item.translations)[0]].name;
          }

          return item;
        });

        context.commit(FETCH_DEPARTMENT, dataLocalized);
      })
      .catch(error => {
        console.log("Error!: ", error);
      });
  }
};

const mutations = {
  [FETCH_DEPARTMENT](state, data) {
    state.collection = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
