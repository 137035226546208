import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service.js";

const state = {
  errors: null,
  collection: [],
};

const getters = {
  contactListCollection(state) {
    return state.collection;
  },
};

const actions = {
  fetchContactList(context) {
    return new Promise((resolve) => {
      let appLocale = i18nService.getActiveLanguage();
      ApiService.get("contact/all").then(({ data }) => {
        // create localized fields
        let dataFormatted = data.map((item) => {
          // let contactData = JSON.parse(item.contact_data)
          let contactData = item.contact_data;

          if (!item.companyType || item.companyType.translations.length == 0) {
            // item.companyTypeName = "Name not defined " + item.id
            item.companyTypeName = "";
            // return item
          } else {
            if (item.companyType.translations[appLocale]) {
              item.companyTypeName =
                item.companyType.translations[appLocale].name;
            } else {
              item.companyTypeName =
                item.companyType.translations[
                  Object.keys(item.companyType.translations)[0]
                ].name;
            }
          }

          if (item.model == "Contact") {
            item.companyName = item.company;
            item.phones = contactData.phone_number;
            item.contactName = item.name;
            item.email;

            if (contactData.mobile_number) {
              item.phones += " / " + contactData.mobile_number;
            }
          } else {
            item.companyName = item.name;

            item.companyId = item.id;
          }
          item.nameArray = [];
          if (item.companyName) {
            item.nameArray.push(item.companyName);
          }
          if (item.contactName) {
            item.nameArray.push(item.contactName);
          }
          if (contactData.phone_number) {
            item.nameArray.push(contactData.phone_number);
          }

          if (contactData.mobile_number) {
            item.nameArray.push(contactData.mobile_number);
          }

          if (item.email) {
            item.nameArray.push(item.email);
          }
          if (item.companyAddresses && item.companyAddresses.billing) {
            item.billingCountries = item.companyAddresses.billing.map(
              (i) => i.country
            );
          }

          item.name = item.nameArray.join(" | ");

          // if (item.companyType.translations[appLocale]) {
          //     item.name = item.translations[appLocale].name
          // } else {
          //     item.name = item.translations[Object.keys(item.translations)[0]].name;
          // }

          return item;
        });
        context.commit("fetchContactList", dataFormatted);
        resolve();
      });
    });
    // .catch((error) => {
    //     console.log('Error!: ', error);

    // });
  },
};

const mutations = {
  fetchContactList(state, data) {
    state.collection = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
