import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service.js";

const state = {
  errors: null,
  collection: []
};

const getters = {
  vehicleCollection(state) {
    return state.collection;
  },

  getVehicleByID: state => id => {
    let item = state.collection.find(item => item.id == id);
    if (!item) {
      item = { name: "" };
    }
    return item;
  }
};

const actions = {
  fetchVehicle(context) {
    return new Promise(resolve => {
      let appLocale = i18nService.getActiveLanguage();
      ApiService.get("transport/vehicle")
        .then(({ data }) => {
          // create localized fields
          let dataLocalized = data.map(item => {
            // if (item.translations) {
            //     let translations = item.translations[appLocale]
            //     if (translations) {
            //         Object.keys(translations).forEach((property) => {
            //             item[property] = item.translations[appLocale][property]
            //         })
            //     } else {
            //         Object.keys(translations).forEach((property) => {
            //             item[property] = item.translations[0][property]
            //         })
            //     }
            // }
            if (item.translations) {
              let translations = item.translations[appLocale];
              if (translations) {
                Object.keys(translations).forEach(property => {
                  item[property] = item.translations[appLocale][property];
                });
              } else {
                for (const [key, value] of Object.entries(
                  item.translations[Object.keys(item.translations)[0]]
                )) {
                  if ("attachments" != key) {
                    item[key] = value;
                  }
                }
              }
            }

            return item;
          });

          context.commit("fetchVehicle", dataLocalized);
          resolve();
        })
        .catch(error => {
          console.log("Error!: ", error);
        });
    });
  }
};

const mutations = {
  fetchVehicle(state, data) {
    state.collection = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
