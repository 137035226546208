import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import Swal from "sweetalert2";

// const BASE_URL = "http://erp.kismetal.hu/api/"
// const BASE_URL = "https://api.kismetal.hu/api/"
// const BASE_URL = "http://km-erp.loc/api/"

// console.log('process.env')
// console.log(process.env)

const BASE_URL = process.env.VUE_APP_BASE_URL;

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    // Vue.axios.defaults.baseURL = "http://localhost";
    Vue.axios.defaults.baseURL = BASE_URL;
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch((error) => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  // get(resource, slug = "") {
  //   return Vue.axios.get(`${resource}/${slug}`)
  //     .catch(error => {
  //       console.log(error);
  //       throw new Error(`[KT] ApiService ${error}`);
  //     });
  // },
  get(resource, slug = "") {
    return Vue.axios.get(BASE_URL + resource + "/" + slug);
  },
  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    // return Vue.axios.post(`${resource}`, params);
    return Vue.axios.post(BASE_URL + resource, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    // return Vue.axios.put(`${resource} / ${slug}`, params);
    return Vue.axios.put(BASE_URL + resource + "/" + slug, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    // return Vue.axios.put(`${resource}`, params);
    return Vue.axios.put(BASE_URL + resource, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(BASE_URL + resource).catch((error) => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  interceptCalls() {
    Vue.axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.response) {
          // Check if it's a 401 error
          if (error.response.status === 401 && error.config.method !== "GET") {
            if (!error.config.url.includes("login") && !error.config.url.includes("logout")) {
              Swal.fire({
                title: "401",
                text: "You are no longer logged in",
                icon: "error",
                confirmButtonText: "OK",
              }).then((result) => {
                if (result.isConfirmed) {
                  localStorage.removeItem("selectedLanguage");
                  window.localStorage.removeItem("permissions");
                  JwtService.destroyToken();
                  window.location.href = '#/login';
                }
              });
            }
          }
          // Return the error for other status codes so that the catch block handles them
          return Promise.reject(error);
  
        } else if (error.request) {
          // The request was made but no response was received
          console.error("No response received from the server", error.request);
          return Promise.reject(error);  // Propagate the error
  
        } else {
          // Something else happened during setting up the request
          console.error("Error in setting up request:", error.message);
          return Promise.reject(error);  // Propagate the error
        }
      }
    );
  }
};

export default ApiService;
