import ApiService from "@/core/services/api.service";
// import i18nService from "@/core/services/i18n.service.js";

export const FETCH_SITE = "fetchWebSite";
export const SITE_COLLECTION = "webSiteCollection";

const state = {
  errors: null,
  collection: [],
};

const getters = {
  webSiteCollection(state) {
    return state.collection;
  },
};

const actions = {
  fetchWebSite(context) {
    // let appLocale = i18nService.getActiveLanguage()
    ApiService.get("companySetting/website")
      .then(({ data }) => {
        // create localized fields
        // let dataLocalized = data.map((item) => {
        //     if (item.translations.length == 0) {
        //         item.name = "Name not defined"
        //         return item
        //     }

        //     if (item.translations[appLocale]) {
        //         item.name = item.translations[appLocale].name
        //     } else {
        //         item.name = item.translations[Object.keys(item.translations)[0]].name;
        //     }

        //     return item
        // })

        // context.commit(FETCH_DEPARTMENT, dataLocalized);
        context.commit("fetchWebSite", data);
      })
      .catch((error) => {
        console.log("Error!: ", error);
      });
  },
};

const mutations = {
  fetchWebSite(state, data) {
    state.collection = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
