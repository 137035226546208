import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
        },

        {
          path: "/humanResource",
          name: "humanResource",
          component: () => import("@/view/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "educations",
              name: "educations",
              component: () =>
                import("@/view/pages/humanResource/educations/Educations.vue"),
            },
            {
              path: "educations/:id",
              component: () =>
                import("@/view/pages/humanResource/educations/Educations.vue"),
            },
            {
              path: "positions",
              name: "positions",
              component: () =>
                import("@/view/pages/humanResource/positions/Positions.vue"),
            },
            {
              path: "positions/:id",
              component: () =>
                import("@/view/pages/humanResource/positions/Positions.vue"),
            },
            {
              path: "employees",
              name: "employees",
              component: () =>
                import("@/view/pages/humanResource/employees/Employees.vue"),
            },
            {
              path: "employees/:id",
              component: () =>
                import("@/view/pages/humanResource/employees/Employees.vue"),
            },
            {
              path: "advances",
              name: "advances",
              component: () =>
                import("@/view/pages/humanResource/advances/Advances.vue"),
            },
            {
              path: "advances/:id",
              component: () =>
                import("@/view/pages/humanResource/advances/Advances.vue"),
            },
          ],
        },

        {
          path: "/maintenance",
          name: "maintenance",
          component: () => import("@/view/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "equipments",
              name: "equipments",
              component: () =>
                import("@/view/pages/maintenance/equipments/Equipments.vue"),
            },
            {
              path: "equipments/:id",
              component: () =>
                import("@/view/pages/maintenance/equipments/Equipments.vue"),
            },
            {
              path: "calendar",
              name: "calendar",
              component: () =>
                import("@/view/pages/maintenance/equipments/Calendar.vue"),
            },
            // {
            //   path: "positions",
            //   name: "positions",
            //   component: () =>
            //     import("@/view/pages/humanResource/positions/Positions.vue"),
            // },
            // {
            //   path: "positions/:id",
            //   component: () =>
            //     import("@/view/pages/humanResource/positions/Positions.vue"),
            // },
            // {
            //   path: "employees",
            //   name: "employees",
            //   component: () =>
            //     import("@/view/pages/humanResource/employees/Employees.vue"),
            // },
            // {
            //   path: "employees/:id",
            //   component: () =>
            //     import("@/view/pages/humanResource/employees/Employees.vue"),
            // },
          ],
        },

        {
          path: "/stock",
          name: "stock",
          component: () => import("@/view/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "stockItems",
              name: "stockItems",
              component: () =>
                import("@/view/pages/stock/stockItems/StockItems.vue"),
            },
            {
              path: "stockItems/:id",
              component: () =>
                import("@/view/pages/stock/stockItems/StockItems.vue"),
            },
            {
              path: "storages",
              name: "storages",
              component: () =>
                import("@/view/pages/stock/storages/Storages.vue"),
            },
            {
              path: "storages/:id",
              component: () =>
                import("@/view/pages/stock/storages/Storages.vue"),
            },
            {
              path: "adjustments",
              name: "adjustments",
              component: () =>
                import("@/view/pages/stock/adjustments/Adjustments.vue"),
            },
            {
              path: "adjustments/:id",
              component: () =>
                import("@/view/pages/stock/adjustments/Adjustments.vue"),
            },

            {
              path: "receives",
              name: "receives",
              component: () =>
                import("@/view/pages/stock/receives/Receives.vue"),
            },
            {
              path: "receives/:id",
              component: () =>
                import("@/view/pages/stock/receives/Receives.vue"),
            },
            {
              path: "releases",
              name: "releases",
              component: () =>
                import("@/view/pages/stock/releases/Releases.vue"),
            },
            {
              path: "releases/:id",
              component: () =>
                import("@/view/pages/stock/releases/Releases.vue"),
            },
            {
              path: "transfers",
              name: "transfers",
              component: () =>
                import("@/view/pages/stock/transfers/Transfers.vue"),
            },
            {
              path: "transfers/:id",
              component: () =>
                import("@/view/pages/stock/transfers/Transfers.vue"),
            },
            // {
            //   path: "quantityunits",
            //   name: "quantityUnits",
            //   component: () => import("@/view/pages/inventory/quantityUnits/QuantityUnits.vue")
            // },
            // { path: '/inventory/quantityunits/:id', component: () => import("@/view/pages/inventory/quantityUnits/QuantityUnits.vue") },

            // {
            //   path: "categories",
            //   name: "categories",
            //   component: () => import("@/view/pages/inventory/categories/Categories.vue")
            // },
            // { path: '/inventory/categories/:id', component: () => import("@/view/pages/inventory/categories/Categories.vue") },

            // {
            //   path: "items",
            //   name: "items",
            //   component: () => import("@/view/pages/inventory/items/Items.vue")
            // },
            // { path: '/inventory/items/:id', component: () => import("@/view/pages/inventory/items/Items.vue") },
          ],
        },

        {
          path: "/inventory",
          name: "inventory",
          component: () => import("@/view/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "pricelists",
              name: "pricelists",
              component: () =>
                import("@/view/pages/inventory/priceList/PriceLists.vue"),
            },
            {
              path: "/inventory/pricelists/:id",
              component: () =>
                import("@/view/pages/inventory/priceList/PriceLists.vue"),
            },
            {
              path: "quantityunits",
              name: "quantityUnits",
              component: () =>
                import(
                  "@/view/pages/inventory/quantityUnits/QuantityUnits.vue"
                ),
            },
            {
              path: "/inventory/quantityunits/:id",
              component: () =>
                import(
                  "@/view/pages/inventory/quantityUnits/QuantityUnits.vue"
                ),
            },

            {
              path: "categories",
              name: "categories",
              component: () =>
                import("@/view/pages/inventory/categories/Categories.vue"),
            },
            {
              path: "/inventory/categories/:id",
              component: () =>
                import("@/view/pages/inventory/categories/Categories.vue"),
            },
            {
              path: "webCategories",
              name: "webCategories",
              component: () =>
                import(
                  "@/view/pages/inventory/webCategories/WebCategories.vue"
                ),
            },
            {
              path: "/inventory/webCategories/:id",
              component: () =>
                import(
                  "@/view/pages/inventory/webCategories/WebCategories.vue"
                ),
            },
            {
              path: "items",
              name: "items",
              component: () => import("@/view/pages/inventory/items/Items.vue"),
            },
            {
              path: "/inventory/items/:id",
              component: () => import("@/view/pages/inventory/items/Items.vue"),
            },
          ],
        },
        // {
        //   path: "media_library/",
        //   name: "media_library",
        //   component: () => import("@/view/media_library/Browser.vue"),
        // },
        {
          path: "/",
          name: "media",
          component: () => import("@/view/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "/media_library",
              name: "media_library",
              component: () => import("@/view/media_library/Browser.vue"),
            },
          ],
        },
        {
          path: "/settings",
          name: "settings",
          component: () => import("@/view/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "userGroups",
              name: "userGroups",
              component: () =>
                import("@/view/pages/settings/userGroups/UserGroups.vue"),
            },
            {
              path: "users",
              name: "users",
              component: () => import("@/view/pages/settings/users/Users.vue"),
            },
            {
              path: "/settings/users/:id",
              component: () => import("@/view/pages/settings/users/Users.vue"),
            },
            {
              path: "currency",
              name: "currency",
              component: () =>
                import("@/view/pages/settings/currency/Currency.vue"),
            },
            {
              path: "country",
              name: "country",
              component: () =>
                import("@/view/pages/settings/country/Country.vue"),
            },
            {
              path: "/settings/country/:id",
              component: () =>
                import("@/view/pages/settings/country/Country.vue"),
            },
            {
              path: "roles",
              name: "roles",
              component: () => import("@/view/pages/settings/roles/Roles.vue"),
            },
            {
              path: "/settings/roles/:id",
              component: () => import("@/view/pages/settings/roles/Roles.vue"),
            },
          ],
        },
        {
          path: "/companysettings",
          name: "companySettings",
          component: () => import("@/view/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "departments",
              name: "departments",
              component: () =>
                import(
                  "@/view/pages/companySettings/departments/Departments.vue"
                ),
            },
            {
              path: "/companysettings/departments/:id",
              component: () =>
                import(
                  "@/view/pages/companySettings/departments/Departments.vue"
                ),
            },
            {
              path: "sites",
              name: "sites",
              component: () =>
                import("@/view/pages/companySettings/sites/Sites.vue"),
            },
            {
              path: "/companysettings/sites/:id",
              component: () =>
                import("@/view/pages/companySettings/sites/Sites.vue"),
            },
            {
              path: "websites",
              name: "websites",
              component: () =>
                import("@/view/pages/companySettings/websites/WebSites.vue"),
            },
            {
              path: "/companysettings/websites/:id",
              component: () =>
                import("@/view/pages/companySettings/websites/WebSites.vue"),
            },
          ],
        },
        {
          path: "/contacts",
          name: "companyType",
          component: () => import("@/view/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "companyTypes",
              name: "companyTypes",
              component: () =>
                import("@/view/pages/contacts/companyTypes/CompanyTypes.vue"),
            },
            {
              path: "/contacts/companyTypes/:id",
              component: () =>
                import("@/view/pages/contacts/companyTypes/CompanyTypes.vue"),
            },
            {
              path: "companyCategories",
              name: "companyCategories",
              component: () =>
                import(
                  "@/view/pages/contacts/companyCategories/CompanyCategories.vue"
                ),
            },
            {
              path: "/contacts/companyCategories/:id",
              component: () =>
                import(
                  "@/view/pages/contacts/companyCategories/CompanyCategories.vue"
                ),
            },
            {
              path: "industries",
              name: "industries",
              component: () =>
                import("@/view/pages/contacts/industries/Industries.vue"),
            },
            {
              path: "/contacts/industries/:id",
              component: () =>
                import("@/view/pages/contacts/industries/Industries.vue"),
            },
            {
              path: "qualifications",
              name: "qualifications",
              component: () =>
                import(
                  "@/view/pages/contacts/qualifications/Qualifications.vue"
                ),
            },
            {
              path: "/contacts/qualifications/:id",
              component: () =>
                import(
                  "@/view/pages/contacts/qualifications/Qualifications.vue"
                ),
            },
            {
              path: "supplierTypes",
              name: "supplierTypes",
              component: () =>
                import("@/view/pages/contacts/supplierTypes/SupplierTypes.vue"),
            },
            {
              path: "/contacts/supplierTypes/:id",
              component: () =>
                import("@/view/pages/contacts/supplierTypes/SupplierTypes.vue"),
            },
            {
              path: "contactList",
              name: "contactList",
              component: () =>
                import("@/view/pages/contacts/contactList/ContactList.vue"),
            },
          ],
        },
        {
          path: "/sales",
          name: "sales",
          component: () => import("@/view/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "salesorders",
              name: "salesorders",
              component: () =>
                import("@/view/pages/sales/salesorders/SalesOrders.vue"),
            },
            {
              path: "/sales/salesorders/:id",
              name: "editsalesorders",
              component: () =>
                import("@/view/pages/sales/salesorders/SalesOrders.vue"),
            },
            {
              path: "salesPoints",
              name: "salesPoints",
              component: () =>
                import("@/view/pages/sales/salesPoint/SalesPoints.vue"),
            },
            {
              path: "/sales/salesPoint/:id",
              component: () =>
                import("@/view/pages/sales/salesPoint/SalesPoints.vue"),
            },
            {
              path: "sourceTypes",
              name: "sourceTypes",
              component: () =>
                import("@/view/pages/sales/sourceTypes/SourceTypes.vue"),
            },
            {
              path: "/sales/sourceTypes/:id",
              component: () =>
                import("@/view/pages/sales/sourceTypes/SourceTypes.vue"),
            },
          ],
        },

        {
          path: "/transport",
          name: "transport",
          component: () => import("@/view/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "transportOrders",
              name: "transportOrders",
              component: () =>
                import(
                  "@/view/pages/transport/transportOrders/TransportOrders.vue"
                ),
            },
            {
              path: "transportOrders/:id",
              component: () =>
                import(
                  "@/view/pages/transport/transportOrders/TransportOrders.vue"
                ),
            },

            {
              path: "itiners",
              name: "itiners",
              component: () =>
                import("@/view/pages/transport/itiners/Itiners.vue"),
            },
            {
              path: "/transport/itiners/:id",
              component: () =>
                import("@/view/pages/transport/itiners/Itiners.vue"),
            },

            {
              path: "vehicles",
              name: "vehicles",
              component: () =>
                import("@/view/pages/transport/vehicles/Vehicles.vue"),
            },
            {
              path: "/transport/vehicles/:id",
              component: () =>
                import("@/view/pages/transport/vehicles/Vehicles.vue"),
            },
          ],
        },

        {
          path: "/manufacture",
          name: "manufacture",
          component: () => import("@/view/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "projects",
              name: "projects",
              component: () =>
                import("@/view/pages/manufacture/projects/Projects.vue"),
            },
            {
              path: "/manufacture/projects/:id",
              component: () =>
                import("@/view/pages/manufacture/projects/Projects.vue"),
            },
          ],
        },

        {
          path: "/procurement",
          name: "procurement",
          component: () => import("@/view/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "purchaseOrder",
              name: "purchaseOrder",
              component: () =>
                import(
                  "@/view/pages/procurement/purchaseOrder/PurchaseOrders.vue"
                ),
            },
            {
              path: "/procurement/purchaseOrder/:id",
              component: () =>
                import(
                  "@/view/pages/procurement/purchaseOrder/PurchaseOrders.vue"
                ),
            },
          ],
        },
      ],
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue"),
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue"),
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue"),
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue"),
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue"),
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login"),
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/Register"),
        },
      ],
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
    },
  ],
});
