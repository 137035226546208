import ApiService from "@/core/services/api.service";

export const FETCH_ROLE = "fetchRole";
export const ROLE_COLLECTION = "roleCollection";

const state = {
  errors: null,
  collection: []
};

const getters = {
  [ROLE_COLLECTION](state) {
    return state.collection;
  }
};

const actions = {
  [FETCH_ROLE](context) {
    ApiService.get("role")
      .then(({ data }) => {
        context.commit(FETCH_ROLE, data);
      })
      .catch(error => {
        console.log("Error!: ", error);
      });
  }

  // createRole(state, payload) {
  //     state.commit('createRole', payload);
  // },

  // async fetchUserGroups({ commit, dispatch }) {
  //     const res = await fetch(
  //         // 'https://jsonplaceholder.typicode.com/posts?_limit=' + limit
  //         'http://erp.kismetal.hu/api/userGroup'
  //     )
  //     // const posts = await res.json().keys
  //     const data = await res.json()
  //     commit('setCollection', data);
  //     dispatch("sayHello")
  //     // commit('updatePosts', posts)
  // },
  // sayHello() { }
};

const mutations = {
  [FETCH_ROLE](state, data) {
    state.collection = data;
  }

  // createRole(state, data) {
  //     state.collection.push(data)
  // },
};

export default {
  state,
  actions,
  mutations,
  getters
};
