import ApiService from "@/core/services/api.service";
// import i18nService from "@/core/services/i18n.service.js";

const state = {
  errors: null,
  collection: []
};

const getters = {
  receiveCollection(state) {
    return state.collection;
  },

  getReceiveByID: state => id => {
    return state.collection.find(item => item.id == id);
  }
};

const actions = {
  fetchReceive(context) {
    // let appLocale = i18nService.getActiveLanguage()
    ApiService.get("stock/receive")
      .then(({ data }) => {
        // create localized fields
        // let dataLocalized = data.map((item) => {
        //     if (item.translations.length == 0) {
        //         item.name = "Name not defined"
        //         return item
        //     }

        //     if (item.translations[appLocale]) {
        //         item.name = item.translations[appLocale].name
        //     } else {
        //         item.name = item.translations[Object.keys(item.translations)[0]].name;
        //     }

        //     return item
        // })

        context.commit("fetchReceive", data);
      })
      .catch(error => {
        console.log("Error!: ", error);
      });
  }
};

const mutations = {
  fetchReceive(state, data) {
    state.collection = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
