import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service.js";

const state = {
  errors: null,
  collection: [],
};

const getters = {
  priceListCollection(state) {
    return state.collection;
  },

  getPriceListByID: (state, getters) => (id) => {
    // let item = state.collection.find(item => item.id == id);
    // if (!item) {
    //     item = { name: "" }
    // }
    // return item

    return new Promise((resolve) => {
      ApiService.get("inventory/priceList/" + id).then(({ data }) => {
        let appLocale = i18nService.getActiveLanguage();
        data.items = data.items.map((item) => {
          if (item.translations.length == 0) {
            item.name = "Name not defined";
            return item;
          }
          if (item.translations[appLocale]) {
            item.name = item.translations[appLocale].name;
          } else {
            item.name =
              item.translations[Object.keys(item.translations)[0]].name;
          }

          let qu = getters.getQuantityUnitByID(item.quantity_unit_id);

          if (typeof qu == "object") {
            item.quantityUnitTranslated = qu.name;
          }

          item.item = { ...getters.getItemByID(item.item_id) };

          return item;
        });

        resolve(data);
      });
    });
  },
};

const actions = {
  fetchPriceList(context) {
    // let appLocale = i18nService.getActiveLanguage()
    ApiService.get("inventory/priceList")
      .then(({ data }) => {
        // create localized fields
        // let dataLocalized = data.map((item) => {
        //     if (item.translations.length == 0) {
        //         item.name = "Name not defined"
        //         return item
        //     }

        //     if (item.translations[appLocale]) {
        //         item.name = item.translations[appLocale].name
        //     } else {
        //         item.name = item.translations[Object.keys(item.translations)[0]].name;
        //     }

        //     return item
        // })

        context.commit("fetchPriceList", data);
      })
      .catch((error) => {
        console.log("Error!: ", error);
      });
  },
};

const mutations = {
  fetchPriceList(state, data) {
    state.collection = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
