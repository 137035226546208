export default {
  getTranslated(translations) {
    let appLocale = localStorage.getItem("language");

    if (translations[appLocale]) {
      return translations[appLocale];
    } else {
      return translations[Object.keys(translations)[0]];
    }
  },

  // img2xUrl(val) {
  //   return `${val.replace(/(\.[\w\d_-]+)$/i, '@2x$1')} 2x`;
  // },

  getNicePrice(price, currency) {
    if (!price || price == undefined) price = 0;

    // if (currency == "HUF") {
    //   let nicePrice = price.toLocaleString("hu-HU", {
    //     style: "currency",
    //     currency: "HUF",
    //   });
    //   return nicePrice.slice(0, -6) + " HUF";
    // }

    // return (
    //   price
    //     .toLocaleString("fr-FR", {
    //       style: "currency",
    //       currency: currency,
    //     })
    //     .slice(0, -2) + " EUR"
    // );

    if (currency == "HUF") {
      // return price.toLocaleString("hu-HU") + " HUF";

      return Math.round(price).toLocaleString("hu-HU") + " HUF";
    }

    return (1 * price).toFixed(2).toLocaleString() + " " + currency;
  },
};
