// USA
export const locale = {
  TRANSLATOR: {
    SELECT: "Select your language",
  },
  MENU: {
    NEW: "new",
    ACTIONS: "Actions",
    CREATE_POST: "Create New Post",
    PAGES: "Pages",
    FEATURES: "Features",
    APPS: "Apps",
    DASHBOARD: "Dashboard",

    systemSettings: "System Settings",
    users: "Users",
    userGroups: "User Groups",
    roles: "Roles",
    currencies: "Currencies",
    countries: "Countries",
    companySettings: "Company Settings",
    departments: "Departments",
    sites: "Sites",
    websites: "Websites",
    MEDIA_LIBRARY: "Media library",
  },

  AUTH: {
    SIGN_OUT_BUTTON: "Sign out",

    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Submit",
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      FORGOT_BUTTON: "Forgot Password",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact",
    },
    LOGIN: {
      TITLE: "Login Account",
      BUTTON: "Sign In",
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset.",
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfuly registered.",
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "Username",
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid",
      // "Invalid Email or Password": "Invalid Email or Password",
      token_expired: "Token expired, You are logged out",
    },
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields",
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Customers",
      CUSTOMERS_LIST: "Customers list",
      NEW_CUSTOMER: "New Customer",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Customer Delete",
        DESCRIPTION: "Are you sure to permanently delete this customer?",
        WAIT_DESCRIPTION: "Customer is deleting...",
        MESSAGE: "Customer has been deleted",
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Customers Delete",
        DESCRIPTION: "Are you sure to permanently delete selected customers?",
        WAIT_DESCRIPTION: "Customers are deleting...",
        MESSAGE: "Selected customers have been deleted",
      },
      UPDATE_STATUS: {
        TITLE: "Status has been updated for selected customers",
        MESSAGE: "Selected customers status have successfully been updated",
      },
      EDIT: {
        UPDATE_MESSAGE: "Customer has been updated",
        ADD_MESSAGE: "Customer has been created",
      },
    },
  },
  PERMISSIONS: {
    module_contacts_menu: "Contact modul",
    module_inventory_menu: "Inventory modul",
    module_sales_menu: "Sales modul",
    module_humanResources_menu: "HR modul",
    module_maintenance_menu: "Maintenance modul",
    module_system_menu: "System settings modul",
    module_transport_menu: "Transport modul",
    module_companySettings_menu: "Company setting modul",
    module_manufacture_menu: "Manufacture modul",
    module_procurement_menu: "Procurement modul",
    contacts_contact_menu: "Contacts - Contact list menu",
    contacts_contact_create: "Contacts - Create contact person",
    contacts_contact_delete: "Contacts - Delete contact person",
    contacts_contact_update: "Contacts - Update contact person",
    contacts_contact_view: "Contacts - View contact person",
    contacts_company_menu: "Contacts - Companies menu",
    contacts_company_create: "Contacts - Create company",
    contacts_company_delete: "Contacts - Delete company",
    contacts_company_update: "Contacts - Update company",
    contacts_company_view: "Contacts - View company",
    contacts_companyCategory_menu: "Contacts - Company categories menu",
    contacts_companyCategory_create: "Contacts - Create company category",
    contacts_companyCategory_delete: "Contacts - Delete company category",
    contacts_companyCategory_update: "Contacts - Update company category",
    contacts_companyCategory_view: "Contacts - View company category",
    contacts_companyType_menu: "Contacts - Company types menu",
    contacts_companyType_create: "Contacts - Create company type",
    contacts_companyType_delete: "Contacts - Delete company type",
    contacts_companyType_update: "Contacts - Update company type",
    contacts_companyType_view: "Contacts - View company type",
    contacts_supplierType_menu: "Contacts - Supplier types menu",
    contacts_supplierType_create: "Contacts - Create supplier type",
    contacts_supplierType_delete: "Contacts - Delete supplier type",
    contacts_supplierType_update: "Contacts - Update supplier type",
    contacts_supplierType_view: "Contacts - View supplier type",
    contacts_industry_menu: "Contacts - Industries menu",
    contacts_industry_create: "Contacts - Create industry",
    contacts_industry_delete: "Contacts - Delete industry",
    contacts_industry_update: "Contacts - Update industry",
    contacts_industry_view: "Contacts - View industry",
    contacts_qualification_menu: "Contacts - Qualifications menu",
    contacts_qualification_create: "Contacts - Create qualification",
    contacts_qualification_delete: "Contacts - Delete qualification",
    contacts_qualification_update: "Contacts - Update qualification",
    contacts_qualification_view: "Contacts - View qualification",
    inventory_item_menu: "Inventory - Items menu",
    inventory_item_create: "Inventory - Create item",
    inventory_item_delete: "Inventory - Delete item",
    inventory_item_update: "Inventory - Update item",
    inventory_item_view: "Inventory - View item",
    inventory_itemCategory_menu: "Inventory - Item categories menu",
    inventory_itemCategory_create: "Inventory - Create item category",
    inventory_itemCategory_delete: "Inventory - Delete item category",
    inventory_itemCategory_update: "Inventory - Update item category",
    inventory_itemCategory_view: "Inventory - View item category",

    inventory_itemWebCategory_menu: "Inventory - Web categories menu",
    inventory_itemWebCategory_create: "Inventory - Create web category",
    inventory_itemWebCategory_delete: "Inventory - Delete web category",
    inventory_itemWebCategory_update: "Inventory - Update web category",
    inventory_itemWebCategory_view: "Inventory - View web category",

    inventory_quantityUnit_menu: "Inventory - Quantity units menu",
    inventory_quantityUnit_create: "Inventory - Create quantity unit",
    inventory_quantityUnit_delete: "Inventory - Delete quantity unit",
    inventory_quantityUnit_update: "Inventory - Update quantity unit",
    inventory_quantityUnit_view: "Inventory - View quantity unit",
    inventory_priceList_menu: "Inventory - Pricelists menu",
    inventory_priceList_create: "Inventory - Create pricelist",
    inventory_priceList_delete: "Inventory - Delete pricelist",
    inventory_priceList_update: "Inventory - Update pricelist",
    inventory_priceList_view: "Inventory - View pricelist",
    inventory_priceListItem_menu: "Inventory - Item pricelists menu",
    inventory_priceListItem_create: "Inventory - Create item pricelist",
    inventory_priceListItem_delete: "Inventory - Delete item pricelist",
    inventory_priceListItem_update: "Inventory - Update item pricelist",
    inventory_priceListItem_view: "Inventory - View item pricelist",
    inventory_itemComponent_menu: "Inventory - Item pricelists menu",
    inventory_itemComponent_create: "Inventory - Create item pricelist",
    inventory_itemComponent_delete: "Inventory - Delete item pricelist",
    inventory_itemComponent_update: "Inventory - Update item pricelist",
    inventory_itemComponent_view: "Inventory - View item pricelist",

    sales_salesOrder_menu: "Sales - Sales orders menu",
    sales_salesOrder_create: "Sales - Create Sales order",
    sales_salesOrder_delete: "Sales - Delete Sales order",
    sales_salesOrder_update: "Sales - Update Sales order",
    sales_salesOrder_view: "Sales - View Sales order",
    sales_salesOrder_assign_group: "Sales - Assign Sales order to group",
    sales_salesOrder_assign_person: "Sales - Assign Sales order to person",
    sales_salesOrder_assign_self: "Sales - Assign Sales order to self",
    sales_salesOrder_approve: "Sales - Approve Sales order",
    sales_salesOrder_contract: "Sales - Contract Sales order",
    sales_salesOrder_lost: "Sales - Lost Sales order",
    sales_salesOrder_revoke: "Sales - Revoke Sales order",
    sales_salesOrder_close: "Sales - Close Sales order",
    sales_salesPoint_menu: "Sales - Sales points menu",
    sales_salesPoint_create: "Sales - Create Sales point",
    sales_salesPoint_delete: "Sales - Delete Sales point",
    sales_salesPoint_update: "Sales - Update Sales point",
    sales_salesPoint_view: "Sales - View Sales point",
    sales_sourceType_menu: "Sales - Source types menu",
    sales_sourceType_create: "Sales - Create source type",
    sales_sourceType_delete: "Sales - Delete source type",
    sales_sourceType_update: "Sales - Update source type",
    sales_sourceType_view: "Sales - View source type",

    humanResources_employee_menu: "HR - Employees menu",
    humanResources_employee_create: "HR - Create employee",
    humanResources_employee_delete: "HR - Delete employee",
    humanResources_employee_update: "HR - Update employee",
    humanResources_employee_update_self: "HR - Update employee self",
    humanResources_employee_view: "HR - View employee",
    humanResources_employee_view_self: "HR - View employee self",
    humanResources_position_menu: "HR - Positions menu",
    humanResources_position_create: "HR - Create position",
    humanResources_position_delete: "HR - Delete position",
    humanResources_position_update: "HR - Update position",
    humanResources_position_update_self: "HR - Update position self",
    humanResources_position_view: "HR - View position",
    humanResources_position_view_self: "HR - View position self",
    humanResources_education_menu: "HR - Education menu",
    humanResources_education_create: "HR - Create education",
    humanResources_education_delete: "HR - Delete education",
    humanResources_education_update: "HR - Update education",
    humanResources_education_view: "HR - View education",
    humanResources_employee_time_view: "HR - View employee time",
    humanResources_employee_time_create: "HR - Create employee time",

    system_setting_menu: "System settings - System settings menu",
    system_setting_update: "System settings - Update system settings",
    system_setting_view: "System settings - View system settings",
    system_user_menu: "System settings - Users menu",
    system_user_create: "System settings - Create user",
    system_user_delete: "System settings - Delete user",
    system_user_update: "System settings - Update user",
    system_user_update_self: "System settings - Update user self",
    system_user_view: "System settings - View user",
    system_user_view_self: "System settings - View user self",
    system_userGroup_menu: "System settings - User groups menu",
    system_userGroup_create: "System settings - Create user group",
    system_userGroup_delete: "System settings - Delete user group",
    system_userGroup_update: "System settings - Update user group",
    system_userGroup_update_self: "System settings - Update user group self",
    system_userGroup_view: "System settings - View user group",
    system_userGroup_view_self: "System settings - View user group self",
    system_role_menu: "System settings - Roles menu",
    system_role_create: "System settings - Create role",
    system_role_delete: "System settings - Delete role",
    system_role_update: "System settings - Update role",
    system_role_view: "System settings - View role",
    system_country_menu: "System settings - Countries menu",
    system_country_update: "System settings - Update country",
    system_country_view: "System settings - View country",
    system_currency_menu: "System settings - Currencies menu",
    system_currency_create: "System settings - Create currency ",
    system_currency_delete: "System settings - Delete currency",
    system_currency_update: "System settings - Update currency",
    system_currency_view: "System settings - View currency",
    system_mediaLibrary_menu: "System settings - Media library menu",

    companySettings_department_menu: "Company settings - Departments menu",
    companySettings_department_create: "Company settings - Create department",
    companySettings_department_delete: "Company settings - Delete department",
    companySettings_department_update: "Company settings - Update department",
    companySettings_department_view: "Company settings - View department",
    companySettings_site_menu: "Company settings - Sites menu",
    companySettings_site_create: "Company settings - Create site",
    companySettings_site_delete: "Company settings - Delete site",
    companySettings_site_update: "Company settings - Update site",
    companySettings_site_view: "Company settings - View site",

    stock_storage_update: "Stock storage Update",
    module_stock_menu: "Stock modul",
    stock: "Stock",
    stock_storage_create: "Stock storage create",
    stock_storage_delete: "Stock storage delete",
    stock_storage_view: "Stock storage view",
    stock_storage_menu: "Stock storage menu",
    stock_receive_create: "Stock receive create",
    stock_receive_delete: "Stock receive delete",
    stock_receive_view: "Stock receive view",
    stock_receive_menu: "Stock receive menu",
    stock_receive_update: "Stock receive update",
    stock_adjustment_create: "Stock adjustment create",
    stock_adjustment_view: "Stock adjustment view",
    stock_adjustment_menu: "Stock adjustment menu",
    stock_release_create: "Stock release create",
    stock_release_view: "Stock release view",
    stock_release_menu: "Stock release menu",
    stock_itemEntity_view: "Stock item entity view",
    stock_itemEntity_menu: "Stock item entity menu",

    stock_transfer_create: "Stock transfer create",
    stock_transfer_delete: "Stock transfer delete",
    stock_transfer_view: "Stock transfer view",
    stock_transfer_menu: "Stock transfer menu",
    stock_transfer_update: "Stock transfer update",

    transport_transportOrder_create: "Transport order create",
    transport_transportOrder_delete: "Transport order delete",
    transport_transportOrder_view: "Transport order view",
    transport_transportOrder_update: "Transport order update",
    transport_transportOrder_menu: "Transport order menu",

    transport_vehicle_create: "Transport vehicle create",
    transport_vehicle_delete: "Transport vehicle delete",
    transport_vehicle_view: "Transport vehicle view",
    transport_vehicle_update: "Transport vehicle update",
    transport_vehicle_menu: "Transport vehicle menu",

    transport_itiner_create: "Transport itiner create",
    transport_itiner_delete: "Transport itiner delete",
    transport_itiner_view: "Transport itiner view",
    transport_itiner_update: "Transport itiner update",
    transport_itiner_menu: "Transport itiner menu",

    manufacture_project_create: "Project create",
    manufacture_project_delete: "Project delete",
    manufacture_project_view: "Project view",
    manufacture_project_update: "Project update",
    manufacture_project_menu: "Project menu",

    procurement_purchaseOrder_create: "Procurement create",
    procurement_purchaseOrder_delete: "Procurement delete",
    procurement_purchaseOrder_view: "Procurement view",
    procurement_purchaseOrder_update: "Procurement update",
    procurement_purchaseOrder_menu: "Procurement menu",
    procurement_purchaseOrder_assign_self: "Procurement assign self",
    procurement_purchaseOrder_assign_group: "Procurement assign group",
    procurement_purchaseOrder_assign_person: "Procurement assign person",

    maintenance_equipment_create: "Equipment create",
    maintenance_equipment_delete: "Equipment delete",
    maintenance_equipment_view: "Equipment view",
    maintenance_equipment_update: "Equipment update",
    maintenance_equipment_menu: "Equipment menu",

    system: "System settings",
    companySettings: "Company settings",
    contacts: "Contacts",
    inventory: "Inventory",
    sales: "Sales",
    humanResources: "HR",
    transport: "Transport",
    module: "Modules",
    manufacture: "Manufacture",
    procurement: "Procurement",
    maintenance: "Maintenance",
    companySettings_website_create: "Company settings - Create website",
    companySettings_website_delete: "Company settings - Delete website",
    companySettings_website_view: "Company settings - View website",
    companySettings_website_update: "Company settings - Update website",
    companySettings_website_menu: "Company settings - Websites menu",
  },

  SALESORDERSSTATUSES: {
    new: "New",
    waiting_for_offer: "Waiting for offer",
    offer_approval: "Offer approval",
    offer_done: "Offer done",
    order_approval: "Order approval",
    ordered: "Ordered",
    contracted: "Contracted",
    to_be_produced: "To be produced",
    in_production: "In production",
    to_be_delivered: "To be delivered",
    preparation: "Preparation",
    delivered: "Delivered",
    closed: "Closed",
    revoked: "Revoked",
  },

  FORMINPUTNAMES: {
    total_net: "Total net",
    total_gross: "Total gross",
    sales_point_id: "Sales point",
    owner_id: "Owner",
    paid: "Paid",
    lost: "Lost",
    source_type_id: "Source type",
    locale: "Locale",
    customer: "Customer",
    billingAddress: "Billing address",
    shippingAddress: "Shipping address",
    expiration_date: "Expiration date",
    delivery_date: "Delivery date",
    delivery_days: "Delivery days",
    price_list_id: "Priece list",
    discount_value: "Discount value",
    discountNetValue: "Discount net value",
    notes: "Comment",
    status: "Status",
    id: "Id",
    sent: "Sent",
    zero_tax: "Zero tax",
    addresses: "Adresses",
    price_net: "Price net",
    so_number: "SO number",
    approved: "Approved",
    company_id: "Company Id",
    contact_id: "Contact Id",
    attachments: "Attachments",
    old_version: "Old version",
    price_gross: "Price gross",
    discount_type: "Discount type",
    sales_order_id: "Sales order id",
  },

  EMAIL: {
    offer: "Árajánlat",
    subject: "Ajánlata elkészült",
    invocation: "Tisztelt ",
    message1: "Köszönjük a türelmét, árajánlata elkészült!",
    message2: "Azonosító: ",
    message3:
      'Az árajánlat a "Megnézem" gombra kattintva egy új böngésző lapon jelenik meg. Amennyiben elnyeri tetszését, és megrendelné a termékeket, kérjük klikkeljen az ajánlat alján található "Megrendelem" gombra!',
    subject_ordered: "Megrendelését rögzítettük",
    message1_ordered: "Köszönjük a visszaigazolást, megrendelése rögzítésre került!",
    message3_ordered:
      'Hamarosan elküldjük önnek a díjbekérőt, a szállítás előtt pedig felvesszük önnel a kapcsolatot! A megrendelés a "Megnézem" gombra kattintva egy új böngésző lapon jelenik meg.',
  },

  ACTIVITY: {
    not_logged_id: "Customer",
  },

  EQUIPMENT: {
    status_null: "",
    status_undefined: "",
    status_0: "New",
    status_5: "In progress",
    status_10: "Completed",
  },

  ADJUSTMENT: {
    status_null: "",
    status_undefined: "",
    status_0: "New",
    status_1: "Under production",
    status_2: "Under procurement",
    status_3: "Remainder",
    status_4: "On hand",
    status_5: "Built in",
    status_6: "Released",
    status_7: "Shipping",
    status_8: "Delivered",
    status_9: "Damaged",
    status_10: "Lost",

    typeOf_1: "Add",
    typeOf_2: "Remove",
    typeOf_3: "Edit",
  },

  TRANSPORT: {
    status_0: "New",
    status_5: "In progress",
    status_10: "Closed",
  },

  ITINER: {
    status_0: "New",
    status_5: "In progress",
    status_10: "Completed",
    status_15: "Closed",
  },

  PAYMENT: {
    cash: "Cash",
    bank_transfer: "Bank transfer",
    credit_card: "Credit card",
  },

  TRANSACTION_TYPE: {
    ModulesStockEntitiesTransfer: "Transfer",
    ModulesSalesEntitiesSalesOrder: "Sales order",
    ModulesManufactureEntitiesProject: "Project",
    ModulesProcurementEntitiesPurchaseOrder: "Purchase order",
    // ModulesTransportEntitiesTransportOrder: "Transport entities order",
  },

  FORMS: {
    new: "New",
    menu: "Menu",
    edit_menu: "Edit menu",
    menus: "Menus",
    cancel: "Cancel",
    save: "Save",
    status: "Status",
    name: "Name",
    label: "Label",
    parent: "Parent",
    icon: "Icon",
    target: "Target",
    slug: "Slug",
    destinations: "Destinations",
    active: "Active",
    inactive: "Inactive",
    are_you: "Are you sure you want to delete this item",

    user: "Felhasználó",
    userGroups: "Felhasználó Csoportok",
    roles: "Jogosultságok",

    firstName: "First name",
    middleName: "Middle name",
    lastName: "Last name",
    language: "Language",
    password: "password",

    seo_title: "SEO title",
    seo_description: "SEO description",
    shared_files: "Root folder",
    countries: "Countries",
    upload: "Upload",
    date_of_created_at: "Date of created at",
    time_of_created_at: "Time of created at",
    highlighted_text: "Highlighted text",
    requirements: "Requirements",
    select: "Select",
    search: "Search",
  },

  TABLE: {
    created_at: "Created at",
    name: "Name",
    status: "Status",
    actions: "Actions",
    type: "Type",
    page: "Page",
    slug: "Slug",
    post: "Post",
    blog_category: "Blog category",
    created: "Created",
    phone: "Phone",
    company: "Company",
    quotationCategory: "Testimonial category",
    logo: "Logo",
    colleagueCategory: "Colleague category",
    id: "ID",
    country: "Country",
    language: "Language",
    currency: "Currency",
    eu: "EU",
    are_you_sure_delete: "Are you sure you want to delete?",
    sign: "Sign",
    exchange_rate: "Exchange rate",
    cannot_delete: "This item is not deletable",
    translated: "Translated",
    first_name: "First name",
    christian_name2: "Middle name",
    last_name: "Last name",
    ok: "Ok",
    cancel: "Cancel",
    yes: "Yes",
    no: "No",
    categories: "Categories",
    value: "Value",
    code: "Code",
    size: "Size",
  },

  MEDIA_LIBRARY: {
    create_new_directory: "New directory",
    rename_directory: "Rename directory",
    rename_file: "Rename file",
    file_upload: "Upload file",
    please_select: "Please select",
    uploading: "Uploading",
    edit_image: "Edit image",
    crop_and_save: "Crop and save",
    file_upload_max_size: "Max upload file size",
    file_upload_max_count: "Upload file max count",
    select_media: "Select media",
    crop_and_select: "Crop and select",
  },

  FORM_INPUT_NAMES: {
    parent_category: "Parent category",
    total_net: "Total net",
    total_gross: "Total gross",
    total_net_special: "Total net special",
    total_gross_special: "Total gross special",
    owner_id: "Owner",
    paid: "Paid",
    lost: "Lost",
    source_type_id: "Source type",
    locale: "Locale",
    customer: "Customer",
    billingAddress: "Billing address",
    shippingAddress: "Shipping address",
    expiration_date: "Expiration date",
    delivery_date: "Delivery date",
    delivery_days: "Delivery days",
    price_list_id: "Price list",
    discount_value: "Discount value",
    discountNetValue: "Discount net value",
    notes: "Comment",
    status: "Status",
    sender_name: "Sender name",
    sender_email: "Sender email",
    languages: "Languages",
    select_logo: "Select logo",
    select_icon: "Válassz ikont",
    select_favicon: "Select favicon",
    select_image: "Select image",
    select_languages: "Select languages",
    select_public_languages: "Select public languages",
    alt_text: "Alt text",
    title: "Title",
    title2: "Title 2nd line",
    description: "Description",
    button_text: "Button text",
    text1: "Headline",
    text2: "Subheadline",
    name: "Name",
    surname: "Vezetéknév",
    message: "Üzenet",
    type: "Type",
    desktop_image: "Desktop size image",
    mobile_image: "Mobile size image",
    // gallery: "Gallery",
    tags: "Tags",
    featured_image: "Featured image",
    card_image: "Card image",
    url: "URL",
    resellerLicence: "Reseller licence",
    youtube_url: "YouTube URL",
    gtm: "GTM",
    recaptcha_site_key: "reCAPTCHA site key",
    recaptcha_site_secret: "reCAPTCHA site secret",
    placement: "Placement",
    select_range: "Select range",
    category: "Category",
    og_site_name: "OG name",
    og_title: "OG title",
    og_type: "OG type",
    og_url: "OG URL",
    og_description: "OG description",
    og_image: "OG image",
    url_prefix: "URL prefix",
    icon: "Icon",
    gallery: "Gallery",
    site_title: "Institute name",
    select_publication_item: "Select publication item",
    gMapURL: "Google map URL",
    question: "Question",
    answer: "Answer",
    select_background_image: "Select background image",
    select_service_image: "Select service image",
    select_usp_image: "Select USP images(svg)",
    select_module_image: "Select module images",
    hero_button_text: "Hero button text",
    hero_button_url: "Hero button URL",
    text: "Text",
    main_page: "Main page",
    company_name: "Name",
    company_tax_number: "Tax number",
    company_registration_number: "Registration number",
    company_bank_account_number: "Bank account number",
    company_address: "Address",
    company_phone: "Phone",
    company_fax: "Fax",
    company_email: "Email",
    company_gmap: "Google map URL",
    company_slogen: "History",
    sub_title: "Sub title",
    select_numbers: "Select numbers",
    select_hero_image: "Select hero image",
    select_solutions_image: "Select solution image",
    block1: "1. block",
    block2: "2. block",
    block3: "3. block",
    block4: "4. block",
    block5: "5. block",
    block6: "6. block",
    block7: "7. block",
    block8: "8. block",
    block9: "9. block",
    block10: "10. block",
    iframe: "IFRAME",
    select_colleagues: "Select Colleagues",
    position: "Position",
    date: "Date",
    special_offer: "Special offer",
    recommend: "We recommend",
    shipping_quantity: "Shipping quantity",
    quantity_unit: "Mértékegység",
    product_offer: "Offered product",
    select_slogan_image: "Select Slogan Image",
    footer_offer: "Footer offer",
    select_youtube_image: "Select Youtube image",
    product_name: "Product name",
    shipping_price: "Shipping price",
    free_shipping_from_price: "Free shipping from given price",
    ordered_at: "Ordered at",
    isPackage: "Product type",
    sub_text1: "Sub text1",
    sub_text2: "Sub text2",
    webshop: "Webshop",
    blog: "Blog",
    banner: "Banner",
    select_banner: "Select banners",
    events: "Events",
    reseller_license: "Reseller license",
    image_resolution: "Image resolution",
    select_pdt: "Add item brochure",
    select_all: "Select all",
  },
  ALERT: {
    choose_only_maxFiles: "You can choose only {maxFiles} files!",
    file_info_title: "File information",
    created_at: "Created at",
    name: "Name",
    mime_type: "Mime type",
    path: "Path",
    size: "Size",
    width: "Width",
    height: "Height",
    pixel: "Pixel",
  },
};
